@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "./colors.scss";
/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'GT Walsheim Pro Regular';
  src: local('GTWalsheimPro-Regular'), url('assets/font/GTWalsheimPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 4px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: #510c76;
  border-radius: 10px;
}

html { height: 100%; width: 100%; }
body {
  margin: 0;
  font-family: 'GT Walsheim Pro Regular' !important;
}
p, h1, h2, h3, h4, h5 {
  margin: 0;
}

// Ngx Carousel
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23510c76'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23510c76'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-control-next, .carousel-control-prev {
  width: 3.5%;
}

// .mat-dialog-container {
//   padding: 0 !important;
// }

.mat-grid-tile-content {
  gap: 1.5vw;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0;
  margin: 0 !important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
